import React, { useEffect } from "react";

import { Typography } from "@material-ui/core";
import { AboutSectionContainer } from "@product-site-frontend/shared";
import { useLocation } from '@reach/router';

import Breadcrumbs from '../../../shared/components/Breadcrumbs';
import PageBox from '../components/PageBox';
import SectionFaq from "../components/SectionFaq";
import questions from '../components/SectionFaq/data/questions'
import SEO from '../components/SEO';

export default function FaqsPage(props) {
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      let top = document.getElementById(hash.slice(1)).offsetTop - 80;
      window.scrollTo(0, top)
    }
  }, [hash])

  return (
    <PageBox>
      <SEO pageData={{ title: 'Часто задаваемые вопросы' }} />
      <Breadcrumbs
        breadcrumbs={[
          {
            href: '/',
            text: 'Домашняя страница'
          },
          {
            text: 'FAQ'
          }
        ]}
        sx={{ breadcrumbsLine: { borderBottom: '1px solid #ebebeb' } }}
      />

      <AboutSectionContainer sxContainer={{ mb: { xs: 0, lg: 0 } }}>
        <Typography sx={{ mb: { xs: 0, md: 0, lg: 0 } }} variant="h1">
          FAQ
        </Typography>
      </AboutSectionContainer>

      {questions.map((elem, index) => {
        return (
          <SectionFaq
            description={elem.description}
            idHash={index + 1}
            key={index}
            title={elem.title}
          />
        );
      })}
    </PageBox>
  );
}
