/**
 * https://github.com/kentcdodds/kentcdodds.com/blob/main/config/website.js
 */
module.exports = {
  siteTitle:
    "Лизинг оборудования для малого бизнеса: взять оборудование в лизинг, условия для юридических лиц и ИП", // Navigation and Site Title
  siteTitleShort: "Arenza", // short_name for manifest
  siteUrl: process.env.GATSBY_ROOT_URL || "https://arenza.ru", // Domain of your site. No trailing slash!
  lang: "ru", // Language Tag on <html> element
  pathPrefix: "/",
  siteLogo: "images/logo.png", // Used for SEO and manifest, path to your image you placed in the 'static' folder
  siteDescription:
    "Аренза – лизинговая компания для малого бизнеса, юридических лиц и ИП ✅ Взять оборудование в лизинг онлайн из любой точки России ✅ Выгодные условия, минимальные проценты",
  keywords: [
    "Аренза",
    "Arenza",
    "Лизинг оборудования онлайн / online",
    "лизинговая компания",
    "лизинг оборудования для бизнеса",
    "купить оборудование в лизинг",
    "купить в лизинг оборудование для производства",
    "лизинг для малого бизнеса",
    "лизинг оборудования для малого бизнеса",
    "пищевое оборудование в лизинг для малого бизнеса",
    "лизинг офисной техники",
    "купить мебель в лизинг",
    "купить в лизинг",
    "лизинг оборудования",
    "аренда лизинг оборудования",
    "стоимость лизинга оборудования",
    "лизинг оборудования цена",
    "взять оборудование в лизинг",
    "помощь в получении лизинга",
    "лизинг оборудования помощь",
  ],
  author: "Sergey Reutskiy", // Author for schemaORGJSONLD
  organization: 'ООО "АРЕНЗА-ПРО"',

  // Manifest and Progress color
  themeColor: "#FE5200",
  backgroundColor: "#232d3e",

  // Social component
  //   twitter: "",
  //   twitterHandle: "",
  //   github: "",
  //   linkedin: "",
  youtube: "https://www.youtube.com/channel/UCgpGldemItlUVOcE_uvltVA",
  //   rss: "",
};
