import PropTypes from "prop-types";
import * as React from "react";

import { useStaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet";

import config from "../../../config/website";
import defaultMetaImage from "../../../static/images/metaImage.jpg";

// TODO: schema-org
// import SchemaOrg from "./schema-org";

function SEO({
  siteMetadata: seo,
  pageData,
  metaImage,
  isBlogPost,
  //   frontmatter: postMeta = postData.childMarkdownRemark.frontmatter || {},
  title = pageData?.title || config.siteTitle,
  description = pageData?.description || seo.description,
  meta = pageData?.meta || seo.meta || [],
  image = `${metaImage || defaultMetaImage}`,
  url = /* postMeta.slug ? `${seo.canonicalUrl}${postMeta.slug}` : */ seo.canonicalUrl,
  microData
  //   datePublished = isBlogPost ? postMeta.datePublished : false,

}) {
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: config.lang,
        }}
      >
        {/* General tags */}
        <title>{title}</title>
        <meta content="e0f273e9d3a5f417" name="yandex-verification" />
        <meta content={description} name="description" />
        <meta content={image} name="image" />
        {meta.map(({ content, name }) => (
          <meta content={content} key={name} name={name} />
        ))}
        {/* OpenGraph tags */}
        <meta content="Arenza" property="og:site_name" />
        <meta content={url} property="og:url" />
        <meta content={config.ogLanguage} property="og:locale" />

        {isBlogPost ? (
          <meta content="article" property="og:type" />
        ) : (
          <meta content="website" property="og:type" />
        )}
        <meta content={title} property="og:title" />
        <meta content={description} property="og:description" />
        <meta content={image} property="og:image" />
        <meta
          content="Лизинг оборудования для малого и среднего бизнеса от 100 000 руб"
          property="og:image:alt"
        />
        {/* <meta content={seo.social.fbAppID} property="fb:app_id" /> */}

        {/* Twitter Card tags */}
        <meta content="summary_large_image" name="twitter:card" />
        <meta content={seo.social.twitter} name="twitter:creator" />
        <meta content={title} name="twitter:title" />
        <meta content={description} name="twitter:description" />
        <meta content={image} name="twitter:image" />
        {microData && (
          <script type="application/ld+json">
            {JSON.stringify(microData)}
          </script>
        )}
      </Helmet>
      {/* <SchemaOrg
        author={seo.author}
        canonicalUrl={seo.canonicalUrl}
        datePublished={datePublished}
        defaultTitle={seo.title}
        description={description}
        image={image}
        isBlogPost={isBlogPost}
        organization={seo.organization}
        title={title}
        url={url}
      /> */}
    </>
  );
}

SEO.propTypes = {
  description: PropTypes.any,
  image: PropTypes.any,
  isBlogPost: PropTypes.bool,
  meta: PropTypes.array,
  metaImage: PropTypes.any,
  microData: PropTypes.shape({
    '@context': PropTypes.string,
    '@type': PropTypes.string,
    image: PropTypes.string,
    url: PropTypes.string,
    logo: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    email: PropTypes.string,
    telephone: PropTypes.string,
    address: PropTypes.shape({
      '@type': PropTypes.string,
      streetAddress: PropTypes.string,
      addressLocality: PropTypes.string,
      addressCountry: PropTypes.string,
      postalCode: PropTypes.string
    })
  }),
  pageData: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    meta: PropTypes.array,
  }),
  siteMetadata: PropTypes.shape({
    author: PropTypes.any,
    canonicalUrl: PropTypes.any,
    description: PropTypes.any,
    organization: PropTypes.any,
    social: PropTypes.shape({
      fbAppID: PropTypes.any,
      twitter: PropTypes.any,
    }),
    title: PropTypes.any,
  }),
  title: PropTypes.any,
  url: PropTypes.any
};

function SEOWithQuery(props) {
  const {
    site: { siteMetadata },
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          description
          canonicalUrl
          image
          meta {
            name
            content
          }
          author {
            name
          }
          organization {
            name
            url
            logo
          }
          social {
            fbAppID
          }
        }
      }
    }
  `);
  return <SEO siteMetadata={siteMetadata} {...props} />;
}

SEOWithQuery.propTypes = {
  isBlogPost: PropTypes.bool,
  //   postData: PropTypes.shape({
  //     childMarkdownRemark: PropTypes.shape({
  //       frontmatter: PropTypes.any,
  //       excerpt: PropTypes.any,
  //     }),
  //   }),
  //   metaImage: PropTypes.string,
};

SEOWithQuery.defaultProps = {
  isBlogPost: false,
  //   postData: { childMarkdownRemark: {} },
  metaImage: null,
};

export default SEOWithQuery;
