import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';

import { Box } from '@material-ui/core';
import { useLocation } from '@reach/router';

import { scrollTo } from '../utils/scrollTo';

PageBox.propTypes = {
  children: PropTypes.node,
  scrollToNode: PropTypes.any,
};

export default function PageBox({ children, scrollToNode }) {
  const { hash } = useLocation();

  const scrollTop = useCallback(() => {
    if (hash !== '#business-requirements') {
      document.body.style.opacity = 0.2;
      window.scrollTo(0, 0);
    }
  }, [hash]);

  useEffect(() => {
    if (scrollToNode) {
      scrollTo(scrollToNode, 'auto');
    }

    window.addEventListener('beforeunload', scrollTop);
    return () => {
      window.removeEventListener('beforeunload', scrollTop);
    };
  }, [scrollToNode, scrollTop]);

  return (
    <Box>{children}</Box>
  );
}