import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from "react";

import { Box, Container, Typography } from "@material-ui/core";
import { useLocation } from '@reach/router';
import { navigate } from "gatsby";

SectionFaq.propTypes = {
  description: PropTypes.node,
  idHash: PropTypes.number,
  title: PropTypes.node,
};

export default function SectionFaq({ description, idHash, title }) {
  const { hash } = useLocation();

  const [ showDescription, setShowDescription ] = useState(false);
  const domParent = useRef(null);

  useEffect(() => {
    const handleClick = e => {
      if (!domParent.current) return;

      if (!domParent.current.contains(e.target)) {
        setShowDescription(false);
      }
    }

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  useEffect(() => {
    if (Number(hash.slice(1)) === idHash) {
      setShowDescription(true)
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const styles = useMemo(() => ({
    container: {
      padding: '0 !important',
      borderBottom: '1px solid #d9dcde',
    },
    title: {
      fontSize: '22px',
      color: showDescription === true ? '#ffffff !important' : '#2484c6',
      fontWeight: showDescription === true ? 500 : 400,
    },
    wrapper: {
      pb: {
        xs: 2,
        lg: 4
      },
      paddingBottom: '0 !important',
    },
    titleWrapper: {
      cursor: 'pointer',
      padding: '17px 0px',
      background: showDescription === true ? '#3990cc !important' : 'none',
      transition: 'all 0.2s ease',
      '&:hover': {
        background: '#f9f9f9',

        'span': {
          textShadow: '0 0 0.35px #3990cc, 0 0 0.35px #3990cc',
        }
      },
    },
    description: {
      fontSize: '16px',
      lineHeight: '38px',
      paddingTop: "40px",
      paddingBottom: "40px",
    },
  }), [showDescription]);


  const handleClick = id => {
    setShowDescription(!showDescription);
    navigate(`/faqs#${id}`);
  }

  return (
    <Container
      component="section"
      id={idHash}
      key={idHash}
      maxWidth="zeroMinWidth"
      ref={domParent}
      sx={{ ...styles.container }}
    >
      <Box onClick={handleClick.bind(this, idHash)} sx={styles.titleWrapper }>
        <Container
          maxWidth="lg"
          sx={{ ...styles.wrapper }}
        >
          <Typography sx={styles.title} variant="caption">
            {title}
          </Typography>
        </Container>
      </Box>

      <Container
        maxWidth="lg"
        sx={{ ...styles.wrapper }}
      >
        {showDescription && (
          <Box>
            <Typography dangerouslySetInnerHTML={{__html: description}}  sx={{ ...styles.description }} />
          </Box>
        )}
      </Container>
    </Container>
  );
}